import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { filter, Subscription, take } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AccountInforData } from '../interfaces/account.interface';
import { LocalStorageService } from './localstorage.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
  private userInfoSubscription: Subscription | null = null;

  constructor(
    private appService: AppService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.userInfoSubscription = this.appService.userInfo$
      .pipe(
        filter((userInfo: AccountInforData | null) => !!userInfo),
        take(1)
      )
      .subscribe(this.handleUserInfoUpdate.bind(this));
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.localStorageService.loginToken) {
      if (this.localStorageService.nowUrl) {
        const nowCode = this.localStorageService.nowUrl.split('/')[2];
        if (nowCode === 'login' || nowCode === 'register' || nowCode === 'forget-password') {
          //转到首页
          this.router.navigateByUrl(`/${this.appService.languageCode}`);
        } else {
          if (nowCode && this.userInfoSubscription) {
            this.userInfoSubscription.unsubscribe();
          }

          const replaceUrl = ['login', 'register', 'forget-password'].some(x => state.url.includes(x));
          //转到登录之前界面
          this.router.navigateByUrl(
            `/${
              ['inviteCode', 'aff'].some(x => this.localStorageService.nowUrl.includes(x))
                ? this.localStorageService.nowUrl.split('?')[0]
                : this.localStorageService.nowUrl
            }`,
            { replaceUrl: replaceUrl }
          );
        }
      } else {
        //转到首页
        this.router.navigateByUrl(`/${this.appService.languageCode}`);
      }
      return false;
    }
    return true;
  }

  /*
   * SportsBook:1, //体育
   * Esports:2, //电子竞技
   * Lottery:3, //彩票
   * LiveCasino:4, //真人娱乐
   * SlotGame:5, //电子游戏
   * Chess:6, //棋牌
   *
   * Sports = /sports/home/index
   * slot = /casino/home/index
   * Live casino = /livecasino/home/index
   * lotto = /lottery/index
   */
  private handleUserInfoUpdate(userInfo: AccountInforData | null) {
    if (userInfo && userInfo.lastGameCategory) {
      let url = '';

      switch (userInfo.lastGameCategory) {
        case 'SportsBook':
        case 'Esports':
          url = `sports/index`;
          break;
        case 'Lottery':
          url = `lottery/index`;
          break;
        case 'SlotGame':
        case 'Chess':
          url = `casino/home/index`;
          break;
        case 'LiveCasino':
          url = `live-casino`;
          break;
        default:
          break;
      }
      this.router.navigateByUrl(`/${this.appService.languageCode}/${url}`);
    }
  }
}
